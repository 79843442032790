"use client";

import React from "react";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { grey } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import Box from "@mui/system/Box";

import { bookNameToSlugMap } from "../utils/books";

const ArticlesByBook = ({
  title,
  books,
  booksWithArticles,
  sx,
}: {
  title: string;
  books: string[];
  booksWithArticles?: string[];
  sx?: SxProps;
}) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box position="relative" sx={{ ...sx }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Paper>
        <Collapse in={expanded} collapsedSize={200}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              p: 1,
              pb: 5,
              overflow: "hidden",
              position: "relative",
            }}
          >
            {books.map((book) => (
              <Button
                key={book}
                variant="outlined"
                color="secondary"
                size="small"
                disabled={!booksWithArticles?.includes(book)}
                href={`/livro/${bookNameToSlugMap[book]}`}
                sx={{ m: 0.5, flexGrow: 1 }}
              >
                {book}
              </Button>
            ))}
          </Box>
        </Collapse>
      </Paper>
      <Box
        onClick={() => setExpanded(!expanded)}
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          height: 40,
          width: "100%",
          background: expanded
            ? "rgba(0,0,0,0)"
            : "linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(240,240,240,0.9332107843137255) 62%, rgba(255,255,255,0) 100%)",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: grey[600],
          "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          },
        }}
      >
        <Typography>mostrar {expanded ? "menos" : "mais"}</Typography>
      </Box>
    </Box>
  );
};

export default ArticlesByBook;
