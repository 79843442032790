"use client";

import Lock from "@mui/icons-material/Lock";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Link from "next/link";

import { HomeIssueWithArticles } from "../../types/Issue";

export default function ArticleList({ items }: { items: HomeIssueWithArticles["articles"] }) {
  return (
    <div>
      <List component="nav" aria-label="Artigos">
        {items.map((item) => {
          return (
            <ListItemButton key={item.slug} component={Link} href={`/artigos/${item.slug}`}>
              <ListItemText
                primary={item.title}
                secondary={item.categories?.length ? item.categories.map((cat) => cat.name).join(", ") : ""}
              />
              <ListItemIcon style={{ justifyContent: "center" }}>{item.protected ? <Lock /> : ""}</ListItemIcon>
            </ListItemButton>
          );
        })}
      </List>
    </div>
  );
}
