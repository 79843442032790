import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/components/ArticlesByBook.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IssueCover3D"] */ "/app/projects/nextjs-app/components/IssueCover3D/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Chip/Chip.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Divider/Divider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Grid/Grid.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Paper/Paper.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/pages_components/home/ArticlesList.tsx");
